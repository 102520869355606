import { QueryStringNormaliseResult } from '../types'
import { filterParams } from '../filterParams'

function findEmptyQueryParams(urlSearchParams: URLSearchParams): string[] {
  const foundParams: string[] = []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  urlSearchParams.forEach((value, key, _parent) => {
    if (!value) {
      foundParams.push(key)
    }
  })
  return foundParams
}

function sortFacetQueryParam(queryParams: URLSearchParams): void {
  const facetValue = queryParams.get('f')
  if (facetValue) {
    const splits = facetValue.split('|').sort()
    const rejoin = splits.join('|')
    queryParams.set('f', rejoin)
  }
}

export function processQuerystring(
  querystring: string,
): QueryStringNormaliseResult {
  const result: QueryStringNormaliseResult = {
    normalisedQueryString: '',
    removedQueryParams: [],
  }

  const urlParamaters = new URLSearchParams(querystring)
  const emptyParams = findEmptyQueryParams(urlParamaters)

  filterParams.concat(emptyParams).forEach(filterParam => {
    if (urlParamaters.has(filterParam)) {
      result.removedQueryParams.push({
        name: filterParam,
        value: urlParamaters.get(filterParam),
      })
      urlParamaters.delete(filterParam)
    }
  })

  sortFacetQueryParam(urlParamaters)
  urlParamaters.sort()
  result.normalisedQueryString = urlParamaters.toString()

  return result
}
