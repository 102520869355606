/* eslint-disable no-param-reassign */
import { processQuerystring } from './processors/processQuerystring'
import { processUrl as processPath } from './processors/processUrl'
import { NormaliserResult } from './NormaliserResult'

export function urlNormaliser(uri: string): NormaliserResult {
  const [path, queryString] = uri.split('?')

  const normaliserResult = new NormaliserResult()
  normaliserResult.setNormalisedPath(processPath(path))

  if (queryString) {
    const processedQuerystring = processQuerystring(queryString)

    normaliserResult.setNormalisedQueryString(
      processedQuerystring.normalisedQueryString,
    )
    normaliserResult.removedQueryParams =
      processedQuerystring.removedQueryParams
  }

  normaliserResult.modificationsMade = normaliserResult.normalisedValue !== uri

  return normaliserResult
}
