import { retiredSearchSubpaths } from '../retiredSearchSubpaths'

export function processUrl(path: string): string {
  let normalisedPath: string = path

  retiredSearchSubpaths.forEach(retiredPath => {
    normalisedPath = normalisedPath.replace(`/search/${retiredPath}`, '/search')
  })

  if (path !== '/' && path.slice(-1) === '/') {
    normalisedPath = normalisedPath.slice(0, -1)
  }

  return normalisedPath
}
