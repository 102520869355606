import { z, ZodIssueCode } from 'zod'
import { loginMethods } from '../types/loginMethods'
import { customerTypes } from '../types/customerTypes'

const testStringAgainstRegex = (regex: RegExp) => (val: string) =>
  regex.test(val)

export const refinementWithMessage = (message: string) => {
  return (received: unknown) => ({ message, params: { received } })
}

export const checkIsLowercase = (val: string) => val === val.toLowerCase()

// due to a bug in GTM we are forced to explicitly set the whole set of data objects to either `object` or `undefined`
// to prevent previous values from bleeding into subsequent events in the datalayer
export const transformEventPayload = (data: Record<string, unknown>) => ({
  ...data,
  event: data.event,
  ecommerce: data.ecommerce,
  basket_data: data.basket_data,
  content_data: data.content_data,
  discount_data: data.discount_data,
  error_data: data.error_data,
  event_data: data.event_data,
  filter_data: data.filter_data,
  product_data: data.product_data,
  reminder_data: data.reminder_data,
  results_data: data.results_data,
  screen_data: data.screen_data,
  user_data: data.user_data,
})

export const zodString = z.string().max(500)
export const zodBoolean = z.boolean()
export const zodNumber = z.number().min(0)

export const zodHyphenatedString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9 |/-]+$/),
  refinementWithMessage(
    'value must be lowercase and only `|/-` special characters are allowed',
  ),
)

export const zodItemIdString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9_|/-]+$/),
  refinementWithMessage(
    'value must be lowercase and only `/|-_` special characters are allowed',
  ),
)

export const zodResultsListString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9\-_|/'’()&£$€ ]*$/),
  refinementWithMessage(
    "value must be lowercase and only `-'’()_|/&` special characters are allowed",
  ),
)

export const zodItemVariantString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9 &|/-]*$/),
  refinementWithMessage(
    'value must be lowercase and only `&|/-` special characters are allowed',
  ),
)

const zodFilterDataString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9\-|/&'£? ]+$/),
  refinementWithMessage(
    "value must be lowercase and only `-|&'£?/` special characters are allowed",
  ),
)

export const zodSearchSuggestionEventLabelString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9/&()'’| ]+$/),
  refinementWithMessage(
    "value must be lowercase and only `/&()'’|` special characters are allowed",
  ),
)

export const zodScreenNameString = zodString.refine(
  testStringAgainstRegex(/[a-z0-9?,!|/\- ]+/),
  refinementWithMessage(
    'value must be lowercase and only `?,!/|-` special characters are allowed',
  ),
)

export const zodProductCategoryString = zodString.superRefine((val, ctx) => {
  if (!/^[a-z0-9/&|_\-,'| ]+$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        "value must be lowercase and only `/&|_-,'\\` special characters are allowed",
      fatal: true,
      params: {
        received: val,
      },
    })

    return z.NEVER
  }

  if (!/^[^|]+( \| [^|]+)*$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'value must contain ` | ` as a delimiter between values with no trailing delimiter',
      fatal: true,
      params: {
        received: val,
      },
    })

    return z.NEVER
  }

  return true
})

export const zodProductNameString = zodString.refine(
  checkIsLowercase,
  refinementWithMessage('value must be lowercase'),
)

export const zodLowerCaseString = zodString.superRefine((val, ctx) => {
  if (!val.trim().length) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message: 'value cannot contain only spaces',
      fatal: true,
    })

    return z.NEVER
  }

  if (!/^[a-z0-9 /|]+$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'value must be lowercase and only `|/` special characters are allowed',
      fatal: true,
      params: { received: val },
    })

    return z.NEVER
  }

  return true
})

const zodCurrencyEnum = z.enum(['AUD', 'GBP', 'EUR', 'USD'])
export const zodLoginMethodEnum = z.enum(loginMethods)

export const zodCustomerTypeEnum = z.enum(customerTypes)

export const zodResultsDataSuggestionTypeEnum = z.enum([
  'navigational',
  'trending',
  'popular',
])

export const zodEmailString = zodString.refine(
  testStringAgainstRegex(/^[a-z0-9]+$/),
  refinementWithMessage(
    '`email` field value should be hashed and cannot contain special characters',
  ),
)

const zodDelimitedLowerCaseString = zodString.superRefine((val, ctx) => {
  if (!/^[a-z0-9|/ ]+$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'value must be lowercase and only ` | ` is allowed as a delimiter',
      fatal: true,
      params: {
        received: val,
      },
    })

    return z.NEVER
  }

  if (!/^[^|]+( \| [^|]+)*$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'value must contain ` | ` as a delimiter between values with no trailing delimiter',
      fatal: true,
      params: {
        received: val,
      },
    })

    return z.NEVER
  }

  return true
})

export const zodAddressCountString = zodDelimitedLowerCaseString.refine(
  testStringAgainstRegex(/^(\d+ ?\| ?\d+)$/),
  refinementWithMessage(
    '`address_count` should follow "<no. of send to me addresses> | <no. of send to them addresses>" pattern',
  ),
)

export const zodUpdateIntiatorEnum = z.enum([
  'delete',
  'duplicate',
  'quantity',
  'size',
  'add to cart',
  'view cart',
])

export const zodResultsDataInputTypeEnum = z.enum([
  'facet suggestion',
  'keyword',
  'filters menu',
  'gallery',
])

export const eventDataSchema = z.object({
  action: zodLowerCaseString,
  category: zodLowerCaseString,
  label: zodString.refine(
    checkIsLowercase,
    refinementWithMessage('value must be lowercase'),
  ),
  non_interaction: z.literal(true).optional(),
  value: zodNumber.int().optional(),
})

export const applicationDataSchema = z.object({
  datalayer_version: zodLowerCaseString,
})

export const basketDataSchema = z.object({
  id: zodHyphenatedString,
  size: zodNumber.optional(),
  update_initiator: zodUpdateIntiatorEnum.optional(),
})

export const contentDataSchema = z.object({
  content_type: zodString.refine(
    testStringAgainstRegex(/[a-z0-9&‘\-|/ ]+/),
    refinementWithMessage(
      'value must be lowercase and only `&‘|/-` special characters are allowed',
    ),
  ),
  item_id: zodString
    .refine(checkIsLowercase, refinementWithMessage('value must be lowercase'))
    .optional(),
})

export const errorDataSchema = z.object({
  id: zodLowerCaseString.or(zodNumber.int()).optional(),
  message: zodString.refine(
    checkIsLowercase,
    refinementWithMessage('value must be lowercase'),
  ),
})

export const filterDataSchema = z
  .object({
    filter_name: zodFilterDataString,
    show_rude_products: zodBoolean,
    number_of_active_filters: zodNumber.int(),
    last_filter_interaction: zodFilterDataString,
  })
  .partial()

export const productDataSchema = z
  .object({
    bundle_id: zodString.refine(
      testStringAgainstRegex(/^[a-z0-9|/\- ]+$/),
      refinementWithMessage(
        '`bundle_id` must be lowercase and only `|/-` special characters are allowed',
      ),
    ),
    design_id: zodHyphenatedString,
    group_card_id: zodLowerCaseString,
    product_brand: zodLowerCaseString,
    product_category: zodProductCategoryString,
    product_id: zodHyphenatedString,
    product_orientation: zodLowerCaseString,
    product_name: zodProductNameString,
    product_price: zodNumber,
    product_quantity: zodNumber.int(),
    product_variant: zodProductNameString,
    project_id: zodLowerCaseString,
  })
  .partial()

export const remindersDataSchema = z
  .object({
    days_until: zodNumber.int(),
    filter: zodLowerCaseString,
    origin: zodLowerCaseString,
    relation: zodLowerCaseString,
  })
  .partial()
  .extend({
    index: zodString.refine(
      testStringAgainstRegex(/\d+[|/]\d+/),
      refinementWithMessage(
        '`index` should follow `<number>/<number>` or `<number>|<number>` pattern',
      ),
    ),
    occasion: zodLowerCaseString,
  })

export const resultsDataSchema = z.object({
  corrected_search_term: zodLowerCaseString.optional(),
  index: zodNumber.int().optional(),
  input_type: zodResultsDataInputTypeEnum,
  number_of_results: zodNumber.int(),
  product_category: zodProductCategoryString,
  results_list: zodResultsListString,
  sort_by: z
    .enum(['newness', 'popularity', 'price_ascending', 'price_descending'])
    .optional(),
  suggestion_type: zodResultsDataSuggestionTypeEnum.optional(),
})

export const screenDataSchema = z.object({
  document_referrer: z.union([zodString.url(), z.literal('')]).optional(),
  document_title: z.union([zodScreenNameString, z.literal('')]).optional(),
  document_url: zodString.url().optional(),
  render_type: z.enum(['client', 'server']),
})

export const userDataSchema = z
  .object({
    address_count: zodAddressCountString,
    customer_id: zodHyphenatedString,
    customer_type: zodCustomerTypeEnum,
    email: zodEmailString,
    is_logged_in: zodBoolean,
    lifetime_order_count: zodNumber,
    login_method: zodLoginMethodEnum,
  })
  .partial()
  .extend({
    is_logged_in: zodBoolean,
  })

export const discountDataSchema = z
  .object({
    name: zodProductCategoryString,
    type: zodLowerCaseString.optional(),
    value: zodNumber.optional(),
    currency: zodCurrencyEnum.optional(),
  })
  .refine(
    /* istanbul ignore next */ data => {
      const bothPresent =
        data.currency !== undefined && data.value !== undefined
      const bothAbsent = data.currency === undefined && data.value === undefined

      return bothPresent || bothAbsent
    },
    {
      message:
        'both value and currency must be either both present or both absent',
      path: ['currency', 'value'],
    },
  )

export const ecommerceItemSchema = z
  .object({
    coupon: zodLowerCaseString,
    currency: zodCurrencyEnum,
    discount: zodNumber,
    design_id: zodHyphenatedString,
    index: zodNumber.int(),
    is_sponsored: z.enum(['true', 'false', 'internal']),
    item_brand: zodLowerCaseString,
    item_category: zodProductCategoryString,
    item_category2: zodString.refine(
      testStringAgainstRegex(/[a-z0-9_/\- ]/),
      refinementWithMessage(
        'value must be lowercase and only `/_-` special characters are allowed',
      ),
    ),
    item_category3: zodHyphenatedString,
    item_category4: zodHyphenatedString,
    item_category5: zodHyphenatedString,
    item_list_id: zodString.refine(
      checkIsLowercase,
      refinementWithMessage('value must be lowercase'),
    ),
    item_list_name: zodString,
    item_name: zodString.refine(
      testStringAgainstRegex(/[a-z0-9!?&/|\- ]/),
      refinementWithMessage(
        'value must be lowercase and only `!/|?&-` special characters are allowed',
      ),
    ),
    item_variant: zodItemVariantString,
    quantity: zodNumber.int(),
    postage_option: zodLowerCaseString,
    recipient_type: zodLowerCaseString,
  })
  .partial()
  .extend({
    item_id: zodItemIdString,
    price: zodNumber,
    quantity: zodNumber.int().positive(),
  })

export const ecommerceSchema = z
  .object({
    coupon: zodLowerCaseString,
    discount: zodNumber,
    payment_type: zodLowerCaseString,
    saved_payment: zodBoolean,
    shipping: zodNumber,
    shipping_tier: zodLowerCaseString,
    shipping_type: zodLowerCaseString,
    tax: zodNumber,
    transaction_id: zodHyphenatedString,
    value: zodNumber,
    items: z.array(ecommerceItemSchema),
  })
  .partial()
  .extend({
    currency: zodCurrencyEnum,
    value: zodNumber,
  })

export const mediaDataSchema = z
  .object({
    media_duration: zodNumber,
    media_milestone: zodNumber,
    media_provider: zodLowerCaseString,
    media_status: zodString.refine(v => v.length > 0, {
      message: '`media_status` cannot be empty.',
    }),
    media_title: zodString,
    media_type: zodLowerCaseString,
    media_url: zodString.url(),
  })
  .partial()

export const cookieConsentDataSchema = z
  .object({
    functional: zodBoolean,
    performance: zodBoolean,
    strictly_necessary: zodBoolean,
    targeting: zodBoolean,
  })
  .partial()

export const marketingDataSchema = z.object({
  advertising_id_enabled: zodBoolean,
  email: zodBoolean,
  fathers_day: zodBoolean,
  general: zodBoolean,
  group_cards: zodBoolean,
  mothers_day: zodBoolean,
  notifications: zodBoolean,
  onboarding: zodBoolean,
  orders: zodBoolean,
  reminders: zodBoolean,
})

export const customFontDataSchema = z.object({
  character_set: zodLowerCaseString,
  character_count: zodLowerCaseString,
  language: zodLowerCaseString,
  pagination: zodString,
})
