export const filterParams: string[] = [
  'page',
  'noapp',
  'pnl',
  'adid',
  'ps',
  'o',
  'gclid',
  'gclsrc',
  'cid',
  'awc',
  'utm_term',
  'utm_content',
  'utm_medium',
  'noapp',
  'utm_source',
  'utm_campaign',
  '_',
]
