import { QueryParam } from './types'

export class NormaliserResult {
  removedQueryParams: QueryParam[]

  modificationsMade: boolean

  normalisedPath: string

  normalisedQueryString: string

  normalisedValue: string

  constructor() {
    this.normalisedPath = ''
    this.normalisedQueryString = ''
    this.normalisedValue = ''
    this.modificationsMade = false
    this.removedQueryParams = []
  }

  setNormalisedPath(normalisedPath: string) {
    this.normalisedPath = normalisedPath
    this.updateNormalisedValue()
  }

  setNormalisedQueryString(normalisedQueryString: string) {
    this.normalisedQueryString = normalisedQueryString
    this.updateNormalisedValue()
  }

  updateNormalisedValue() {
    this.normalisedValue = this.normalisedQueryString
      ? `${this.normalisedPath}?${this.normalisedQueryString}`
      : this.normalisedPath
  }
}
