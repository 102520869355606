/** ****************** BEWARE ************************
Additional experiments affect caching
- Only provide experiments actively used by the API's
- Expiry must be within 90 days
- Your experiment will not be passed after the expiry
- Extend expiry via additional PR's only if necessary
- Expiry date should be in format new Date('YYYY-MM-DD')
*****************************************************/
export type Experiment = { key: string; expiry: Date }
export const graphQlExperimentsWhitelist: Array<Experiment> = [
  {
    key: 'basket-personalised-promotion-model',
    expiry: new Date('2025-05-26'),
  },
  {
    key: 'mnpg-price-trial',
    expiry: new Date('2025-04-09'),
  },
  {
    key: 'price-trial',
    expiry: new Date('2025-04-09'),
  },
  {
    key: 'homepage-mission-buttons-imagery',
    expiry: new Date('2025-05-01'),
  },
  {
    key: 'search-use-competitive-ranking',
    expiry: new Date('2025-03-20'),
  },
  {
    key: 'search-use-newness-v2',
    expiry: new Date('2025-04-30'),
  },
  {
    key: 'search-learning-to-rank',
    expiry: new Date('2025-05-05'),
  },
  {
    key: 'search-use-dropdown-filters-v2',
    expiry: new Date('2025-05-05'),
  },
  {
    key: 'search-use-department-suggestions-v2',
    expiry: new Date('2025-06-09'),
  },
  {
    key: 'search-product-tile-ux',
    expiry: new Date('2025-05-26'),
  },
]
